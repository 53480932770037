import {
  AlignItems,
  Box,
  BoxProps,
  Color,
  ColorPreset,
  FontWeight,
  JustifyContent,
  ResponsiveValue,
  SpaceScale,
  Text,
  TextProps,
  TypeScale,
} from "@gocardless/flux-react";

interface StepIndicatorProps {
  number?: number;
  spaceAfter?: ResponsiveValue<SpaceScale>;
  containerStyle?: Partial<BoxProps>;
  textStyle?: Partial<TextProps>;
}

export const StepIndicator = ({
  number = 1,
  spaceAfter = 0,
  containerStyle = {},
  textStyle = {},
}: StepIndicatorProps) => {
  const defaultContainerStyle: BoxProps = {
    layout: "flex",
    flexDirection: "row",
    justifyContent: JustifyContent.Center,
    alignItems: AlignItems.Center,
    alignSelf: AlignItems.Start,
    flexShrink: 0,
    width: "32px",
    height: "32px",
    borderWidth: 1,
    borderColor: Color.Greystone_700,
    borderRadius: 2,
    bg: Color.White,
  };

  const defaultTextStyle: TextProps = {
    color: ColorPreset.BorderOnLight_01,
    weight: FontWeight.SemiBold,
    size: TypeScale.Size_02,
  };

  return (
    <Box {...defaultContainerStyle} {...containerStyle} spaceAfter={spaceAfter}>
      <Text {...defaultTextStyle} {...textStyle}>
        {number}
      </Text>
    </Box>
  );
};
